

.impressIconContainer {
    opacity: 0.6;
}

.impressIcon {
    border-radius: 100%;
    padding: 5px;
    color: #FFFFFF;
    background-color: red;

}

.impressDetailsCell {
    padding: 10px;
}

.impressSocialIcon {
    width: 50px;
    height: 50px;
    color: red;
    opacity: 0.6;

}

.impressItem#social {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.impressItem {
    margin: 20px;
}